* {
  font-family: 'HVD Comic Serif Pro', sans-serif;
 }

@import url('https://fonts.cdnfonts.com/css/kg-happy');
@import url('https://fonts.cdnfonts.com/css/hvd-comic-serif');
@import url('https://db.onlinewebfonts.com/c/e1eba9549c54686b22150ec670362ec5?family=HVD+Comic+Serif+Pro');
@import url('https://db.onlinewebfonts.com/c/901c9ee78e00f7f737b6488d92ed8e42?family=KG+HAPPY');
@font-face {font-family: "KG HAPPY"; src: url("https://db.onlinewebfonts.com/t/901c9ee78e00f7f737b6488d92ed8e42.eot"); src: url("//db.onlinewebfonts.com/t/901c9ee78e00f7f737b6488d92ed8e42.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/901c9ee78e00f7f737b6488d92ed8e42.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/901c9ee78e00f7f737b6488d92ed8e42.woff") format("woff"), url("//db.onlinewebfonts.com/t/901c9ee78e00f7f737b6488d92ed8e42.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/901c9ee78e00f7f737b6488d92ed8e42.svg#KG HAPPY") format("svg"); }


@font-face {font-family: "HVD Comic Serif Pro";
  src: url("https://db.onlinewebfonts.com/t/e1eba9549c54686b22150ec670362ec5.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/e1eba9549c54686b22150ec670362ec5.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("https://db.onlinewebfonts.com/t/e1eba9549c54686b22150ec670362ec5.woff2") format("woff2"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/e1eba9549c54686b22150ec670362ec5.woff") format("woff"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/e1eba9549c54686b22150ec670362ec5.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("https://db.onlinewebfonts.com/t/e1eba9549c54686b22150ec670362ec5.svg#HVD Comic Serif Pro") format("svg"); /* iOS 4.1- */
}

@import url('https://fonts.cdnfonts.com/css/liquidism-part-2');


@media screen and (max-width: 499px) {
  .concatenate{
    color: rgb(146, 146, 146);
    font-size: 11px;
    margin-top: 20px;
  }
  
  textarea{
    width: 100%;
    height: 30vh;
    border: none;
    background-color: black;
    color: white;
    overflow-y: scroll;
  }
  
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(204, 204, 204);
    font-size: 10px;
  }
  
  
  a{
    font-size: 10px;
    color: rgb(204, 204, 204);
    text-decoration: none;
  }
  
  a:hover {
    font-size: 10px;
    color: #888;
    text-decoration: underline;
  }
  #customers tr:nth-child(even){background-color: #f2f2f200;}
   
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff00;
    color: white;
    text-align: center;
    font-weight: 100;
  }
  #js-canvas{
    display: none;
  }

  .connectbtn3{
    width: fit-content;
    z-index: 1000;
  }
  .submitNickname{
    color: whitesmoke;
    font-size: small;
    font-style: italic;
  }

  .line1Mobile{
    display: flex;
  }

  .connectAbout {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  .line2Mobile{
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .socialIcons2{
    display: flex;
    
  }
  
  .osPic {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 3%;
    padding-right: 3%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -80%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 10000000000000000;
    background-attachment: fixed;

  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background-color: #000000;
     transform: translate(-50%, -50%);
    position: fixed;
    width: 50%;
    height: 100vh;
    top: 50%;
    left: 30%;
    z-index: 10000000000000000;
    background-attachment: fixed;

   }
  
  .inputAndSubmit {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }
  
  .inputAndSubmit img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 35px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 20%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 16px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }

  .storyConMob{
    color: white;
    font-size: 16px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 25px;
    text-align: center;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
   }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    text-align: center;
  }
  
  .mainPic {
    width: 35%;
    display: none;
  }

  .mainPicMobile {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .introMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    display: none;
  }

  .rightAbout {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }

  .rightMobile {
    flex-flow: column nowrap;
    display: flex;
    padding-bottom: 20px;
     justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 25px;
  }
  
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
 
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
   }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }

  
  .connectMobile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 10px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: fit-content;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: fit-content;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 45%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 53%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: fit-content;
  }
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: fit-content;
  }

  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 60px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    z-index: 10000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 35px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 270px;
    height: 219px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 270px;
    height: 199px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 70vh;
    position: relative;
   }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 30px;
    height: 30px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 18px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 16px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -40%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 40px;
    height: 40px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 40px;
    height: 40px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 80px;

  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 90px;
    position: absolute;
     top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
    width: 200px;
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 15px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
     margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  /* Animation */
  .anim-typewriterMobile {
    animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
  }

  .anim-typewriter{
    display: none;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 5px;
    }
  }


   /* Animation */
   .anim-typewriterMobile {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 690px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    display: none;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 520px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
   }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
  
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
    margin-left: -370px;
  }
  
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 400px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 85%;
     margin-right: 100px;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
    font-size: 10px;
  }
  
  .devil img {
    width: 20%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 50px;
   height: 50px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   margin-top: 100%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
  }
  
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 80%;
     margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 24px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 30px;
  }
  
  .bidArea{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     width: 100%;
  }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    flex-flow: column nowrap;  
    }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 18px;
  width: 150px;
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 15px;
    padding: 5px;
    border-radius: 10px;
  
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
  
  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 35px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .images_show {
    width: 70%;
   }
  
  .images_show3 {
    width: 70%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 30%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    font-size: small;

  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
    font-size: small;
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: small;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: 18px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 10px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100000;
     width: fit-content;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 200px;
    height: 200px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 30px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
   }
  
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
  
   }
  
   .winner{
    text-align: center;
    font-size: 30px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 20px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 18px;
    padding: 10px;
    width: 280px;
    box-shadow: -5px 5px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 90%;
    height: 40vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
    font-size: 15px;
    margin-right: 20px;
  }
  
  .login{
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
 
  .concatenate{
    color: rgb(146, 146, 146);
    font-size: 11px;
    margin-top: 20px;
  }
  
  textarea{
    width: 100%;
    height: 30vh;
    border: none;
    background-color: black;
    color: white;
    overflow-y: scroll;
  }
  
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(204, 204, 204);
    font-size: 10px;
  }
  
  
  a{
    font-size: 10px;
    color: rgb(204, 204, 204);
    text-decoration: none;
  }
  
  a:hover {
    font-size: 10px;
    color: #888;
    text-decoration: underline;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f200;}
   
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff00;
    color: white;
    text-align: center;
    font-weight: 100;
  }
  #js-canvas-mobile{
    display: none;
  }

  .submitNickname{
    color: whitesmoke;
    font-size: small;
    font-style: italic;
  }

  .connectbtn3{
    width: fit-content;
    z-index: 1000;
    color: black;
  }

  .connectbtn3:hover{
    color: black;

  }
  
  .line1Mobile{
    display: flex;
  }
  .line1Mobile{
    display: flex;
  }

  .connectAbout {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  .line2Mobile{
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .socialIcons2{
    display: flex;
    
  }

  .osPic {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 10000000000000000;
  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .inputAndSubmit {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

  }
  
  .inputAndSubmit img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 35px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 20%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }

  .storyConMob{
    color: white;
    font-size: 18px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 40px;
    text-align: center;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
   }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    text-align: center;
  }
  
  .mainPic {
    width: 35%;
    display: none;
  }

  .mainPicMobile {
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .introMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    display: none;
  }

  .rightAbout {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }

  .rightMobile {
    flex-flow: column nowrap;
    display: flex;
    padding-bottom: 20px;
     justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
     justify-content: center;
  }
  
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
    width: 50px;
  }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }

  
  .connectMobile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: 130px;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 13px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: 130px;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 35%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 70%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: fit-content;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 60px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    z-index: 10000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 50px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 400px;
    height: 324px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 400px;
    height: 295px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 30px;
    height: 30px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 18px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 16px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -15%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 80px;
  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 150px;
    position: absolute;
     top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
    width: 200px;
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 15px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
     margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  /* Animation */
  .anim-typewriterMobile {
    animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
  }

  .anim-typewriter{
    display: none;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 5px;
    }
  }


   /* Animation */
   .anim-typewriterMobile {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 690px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    display: none;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 520px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
  
  }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
  
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
    margin-left: -370px;
  }
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 450px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 100%;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
    font-size: 10px;
  }
  
  .devil img {
    width: 20%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 50px;
   height: 50px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   padding-top: 2%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
  }
  
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 80%;
    margin-right: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 24px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 40px;
  }
  
  .bidArea{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
   }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    flex-flow: column nowrap;  
  }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 20px;
  width: 200px;
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
  
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
  
  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 35px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 150px;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .images_show {
    width: 80%;
     margin-bottom: 200px;
  }
  
  .images_show3 {
    width: 50%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 90%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 80px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    font-size: small;

  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 80px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
    font-size: small;
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: small;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: small;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100000;
     width: 150px;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 200px;
    height: 200px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 40px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
   }
  
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
  
   }
  
   .winner{
    text-align: center;
    font-size: 40px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 20px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    width: 350px;
    box-shadow: -5px 5px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 60%;
    height: 40vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
  }
  
  .login{
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
 
.concatenate{
  color: rgb(146, 146, 146);
  font-size: 11px;
  margin-top: 20px;
}

textarea{
  width: 100%;
  height: 30vh;
  border: none;
  background-color: black;
  color: white;
  overflow-y: scroll;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 50px;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  color: rgb(204, 204, 204);
  font-size: 10px;
}


a{
  font-size: 10px;
  color: rgb(204, 204, 204);
  text-decoration: none;
}

a:hover {
  font-size: 10px;
  color: #888;
  text-decoration: underline;
}
#customers tr:nth-child(even){background-color: #f2f2f200;}
 
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff00;
  color: white;
  text-align: center;
  font-weight: 100;
}

 
  .submitNickname{
    color: whitesmoke;
    font-size: small;
    font-style: italic;
  }
  #js-canvas-mobile{
    display: none;
  }
  .storyConMob{
    display: none;
  }
  
  .connectbtn3{
    width: fit-content;
    z-index: 1000;
  }
  
  .line1Mobile{
    display: flex;
  }
  .intro2{
    display: none;
  }

  .connectAbout {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  .line2Mobile{
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .socialIcons2{
    display: flex;
    
  }

  .osPic {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 10000000000000000;
  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .inputAndSubmit {
    display: flex;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inputAndSubmit img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 50px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 5%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 60px;
    text-align: center;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
   }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 20px;
  }
  
  .mainPic {
    width: 35%;
    display: none;
  }

  .mainPicMobile {
    width: 50%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .introMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    display: none;
  }

  .rightAbout {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }

  .rightMobile {
    flex-flow: column nowrap;
    display: flex;
    padding-bottom: 20px;
     justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
    width: 50px;
  }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }

  
  .connectMobile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: 150px;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: 150px;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 35%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 70%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 300px;
  }
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 300px;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 60px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    z-index: 10000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 65px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 400px;
    height: 324px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 400px;
    height: 295px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 18px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 50px;
    height: 50px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 20px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 18px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -15%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 290px;
    position: absolute;
     top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
    width: 200px;
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
     margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 690px;
    }
  }

  
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 520px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
  
  }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
  
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
    margin-left: -370px;
  }
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 600px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 100%;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
  }
  
  .devil img {
    width: 20%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 50px;
   height: 50px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   padding-top: 2%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
  }
  
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 30%;
    margin-right: 30px;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 24px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 40px;
  }
  
  .bidArea{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 30px;
  }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    
  }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 20px;
  width: 200px;
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
  
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
  
  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 60px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .images_show {
    width: 90%;
  }
  
  .images_show3 {
    width: 90%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 90%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100000;
     width: 150px;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 350px;
    height: 350px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 50px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
   }
  
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
  
   }
  
   .winner{
    text-align: center;
    font-size: 55px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    width: 350px;
    box-shadow: -5px 5px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 40%;
    height: 40vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
  }
  
  .login{
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
 
.concatenate{
  color: rgb(146, 146, 146);
  font-size: 11px;
  margin-top: 20px;
}

textarea{
  width: 100%;
  height: 30vh;
  border: none;
  background-color: black;
  color: white;
  overflow-y: scroll;
}

#customers {
   border-collapse: collapse;
  width: 100%;
  margin-top: 50px;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 5px;
  color: rgb(204, 204, 204);
  font-size: 10px;
}


a{
  font-size: 10px;
  color: rgb(204, 204, 204);
  text-decoration: none;
}

a:hover {
  font-size: 10px;
  color: #888;
  text-decoration: underline;
}

#customers tr:nth-child(even){background-color: #f2f2f200;}
 
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff00;
  color: white;
  text-align: center;
  font-weight: 100;
}

  .submitNickname{
    color: whitesmoke;
    font-size: small;
    font-style: italic;
  }
  #js-canvas-mobile{
    display: none;
  }
  .storyConMob{
    display: none;
  }
  .connectbtn3{
    width: fit-content;
    z-index: 1000;
  }
  
  .line1Mobile{
    display: flex;
  }
  .intro2{
    display: none;
  }

  .line2Mobile{
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .socialIcons2{
    display: flex;
    
  }
  
  .osPic {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 10000000000000000;
  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .inputAndSubmit {
    display: flex;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inputAndSubmit img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 70px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 1%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 60px;
    text-align: center;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
   }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 20px;
  }
  
  .mainPic {
    width: 35%;
    display: none;
  }

  .mainPicMobile {
    width: 50%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .introMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    display: none;
  }

  .rightMobile {
    flex-flow: column nowrap;
    display: flex;
    padding-bottom: 20px;
     justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
    width: 50px;
  }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }

  .connect{
    display: none;
  }
  
  .connectMobile {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
    z-index: 100;
    width: 200px;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
    z-index: 100;
    width: 200px;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 35%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 70%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 300px;
  }
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 300px;
  }
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 60px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 400px;
    height: 324px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 400px;
    height: 295px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 18px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 50px;
    height: 50px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 25px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 20px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -15%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 400px;
    position: absolute;
     top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
    width: 200px;
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
     margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 690px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 520px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
  
  }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
  
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
    margin-left: -370px;
  }
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 600px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 100%;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
  }
  
  .devil img {
    width: 10%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 50px;
   height: 50px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   padding-top: 2%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
  }
  
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 30%;
    margin-right: 30px;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 24px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 55px;
  }
  
  .bidArea{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 30px;
  }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    
  }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 20px;
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
  
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
  
  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 60px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .images_show {
    width: 90%;
  }
  
  .images_show3 {
    width: 90%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 90%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
     padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
     width: 200px;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 350px;
    height: 350px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 50px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
   }
  
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
  
   }
  
   .winner{
    text-align: center;
    font-size: 55px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    width: 350px;
    box-shadow: -5px 5px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 40%;
    height: 40vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
  }
  
  .login{
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .concatenate{
    color: rgb(146, 146, 146);
    font-size: 11px;
    margin-top: 20px;
  }
  
  textarea{
    width: 100%;
    height: 30vh;
    border: none;
    background-color: black;
    color: white;
    overflow-y: scroll;
  }
  
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(204, 204, 204);
    font-size: 15px;
  }

  a{
    font-size: 15px;
    color: rgb(204, 204, 204);
    text-decoration: none;
  }

  a:hover {
    font-size: 15px;
    color: #888;
    text-decoration: underline;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f200;}
   
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff00;
    color: white;
    text-align: center;
    font-weight: 100;
  }
  
  .submitNickname{
    color: whitesmoke;
    font-size: large;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
  }
  #js-canvas-mobile{
    display: none;
  }
  .rightAbout{
    display: none;
  }
  .storyConMob{
    display: none;
  }
  .connectbtn3{
    width: fit-content;
    z-index: 1000;
  }
  .line1Mobile{
    display: flex;
  }
  .rightMobile{
    display: none;
  }
  .mainPicMobile{
    display: none;
  }
  .intro2{
    display: none;
  }

.osPic {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.osPicB {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.osPicB:hover {
  width: 22px;
  height: 22px;
  cursor: pointer;
  background-image: url('./assets/discord.png');
}

.osPic2 {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.price {
  text-align: center;
  padding: 15px;
  font-size: 20px;
  color: #ffffff;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: max-content;
}

.boxWrap2 {
  background-color: #000000;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.2;
}

.boxWrap2Rm {
  background-color: #000000;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.2;
  height: 160vh;
}

.boxWrap3B {
  background-color: #003467;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.2;
}

.boxWrap2Main {
  background-color: #000000;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
  overflow: scroll;
  overflow-x: hidden;
}

.secretsMain {
  background-color: #000000;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
}

.scratchMain{
  background-color: #000000;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
}

.secrets {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;

}

.inputAndSubmit {
  display: flex;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.inputAndSubmit img {
  width: 40px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.inputAndSubmit button {
  background-color: rgba(0, 0, 255, 0);
  border: none;
}

.secretsT {
  font-size: 70px;
  text-align: center;
  font-family: 'Liquidism part 2', sans-serif;
  padding-top: 1%;
  background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.boxWrap3Main {
  background-color: #003467;
}

.boxWrap3 {
  background-color: #003467;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.2;
}

.boxWrap3 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10%;
  margin-bottom: 10%;
}


.boxWrapB {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  font-size: 25px;
  color: black;
  font-weight: 500;
  padding-top: 5%;
}

.bH {
  font-size: 25px;
  color: #7eaeff;
  font-weight: 500;
  padding-top: 5%;
}

.noteB {
  font-size: 18px;
  color: #7eaeff;
  margin-top: 5%;
}

.storyCon {
  color: white;
  font-size: 20px;
  margin-top: 5%;
  letter-spacing: 1px;
  text-align: justify;
}

.storyCon-1 {
  color: white;
 
  text-align: justify;
  background-size: contain;
  background-repeat: no-repeat;
}

.bigWord {
  font-size: 45px;
  color: rgb(255, 255, 255);
}

.storyConM {
  color: black;
  font-size: 21px;
  margin-top: 8%;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 100%;
  text-align: center;
 }

.storyRight2-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.storyRight2-2-2 {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.about1 {
  margin-right: 3.5%;
}

.about2 {
  margin-top: 1.5%;
}

.storyRight2 {
  width: 100%;
}

.storyRight3 {
  width: 60%;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 80px;
  text-align: left;
  z-index: 1000;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  letter-spacing: 5px;
  font-family: 'KG HAPPY', sans-serif;

}

.introMain2 {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  flex-flow: column nowrap;
  text-align: left;
  overflow: hidden;
}

.intro2 {
  color: rgb(255, 255, 255);
  font-size: 20px;

}

.mainPic {
  width: 35%;
}

.introMain {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: space-evenly;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-left: auto;
  margin-right: auto;
  width: 5%;
  justify-content: space-between;
}

.discord {
  margin-right: 20px;
  background-color: rgb(255, 255, 255);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.5;
  transition: transform .2s;

}

.discord:hover {
  background-color: rgb(83, 83, 83);
  cursor: pointer;
}

.discordBlue {
  margin-right: 20px;
  background-color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.5;
  transition: transform .2s;
}

.discordBlue:hover {
  background-color: #7eaeff;
  cursor: pointer;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: black;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: black;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  transition: transform .2s;

  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;

}

.connect2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 5px;
  margin-right: 2%;

}

.connectbtn {
  margin-left: 10px;
  margin-right: 10px;
  color: #000000;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  transition: 0.6;
   z-index: 100;
}

.connectbtnActive {
  margin-left: 10px;
  margin-right: 10px;
  color: #e4e4e4;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: rgb(54, 54, 54);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  transition: 0.6;
   z-index: 100;
}

.button:focus {
  background-color: rgb(158, 60, 60);
}

.connectbtnBlue {
  margin-left: 10px;
  margin-right: 10px;
  color: #7eaeff;
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  transition: 0.6;
}

.connectbtnActiveB {
  margin-left: 10px;
  margin-right: 10px;
  color: #7eaeff;
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
  border: solid #7eaeff 1px;
  transition: 0.6;
}

.logoDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.connectbtn:hover {
  background-color: rgb(54, 54, 54);
  color: #e4e4e4;
}

.connectbtnBlue:hover {
  background-color: #7eaeff;
  color: black;
}

.stickyHand {
  width: 28%;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  left: 70%;
  margin-top: -40%;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
 }

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

}

.mintbuttondiv {
  margin-top: 20px;
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  margin-top: 20px;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  width: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
  cursor: pointer;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  width: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
  cursor: pointer;

}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  font-size: 25px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  padding: 10px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  width: 300px;
}

.btnfos-0-3-Dis {
  margin-top: 20px;
  font-size: 25px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  padding: 10px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  width: 300px;
  background-color: #474747;
  color: rgb(97, 97, 97);
}

.btnfos-0-3:hover {

  color: rgb(78, 78, 78);
  cursor: pointer;

}

.btnfos-0-3:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
}

.nftamount {

  color: #ffffff;
  font-size: 60px;
   text-shadow: 4px 3px 0 #474747;
   margin-top: auto;
   margin-bottom: auto;
   justify-content: center;
   display: flex;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: rgb(124, 199, 74);
}


.headers {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 15px;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}


.introduction {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}


.wallet2 {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: black;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: transform .2s;
}

.wallet2:hover {
  background-color: rgba(255, 255, 255, 0.315);
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: black;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 70px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-bottom: 5px;
   text-shadow: 4px 3px 0 #383838;
  letter-spacing: 1px;
  color: white;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}



.footer {
  background-color: black;
  position: static;
  margin-top: 5%;
  padding: 60px;

}

.copyright {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  text-decoration: underline;
  font-style: italic;
}

.copyright2 {
  color: rgb(255, 255, 255);
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-top: 1%;
}

.footer2 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.rmF {
  color: black;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.footerDetails {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: -8%;
  padding-left: 7%;
  padding-right: 7%;
}

.footerDetails2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 7%;
  padding-bottom: 2.5%;
}

.footerDetails3 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 14%;
  padding-bottom: 2.5%;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

.logo {
  width: 250px;
  height: 69px;
}

.glassAndCon {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.glass {
  width: 75%;
}

.light {
  background-color: black;
  min-height: 100vh;
  height: max-content;
}

.pagebtn {
  background-color: rgba(0, 0, 128, 0);
  border: none;
  color: black;
  font-weight: 500;
}

.storyH2 {
  color: #ffffff;
  font-size: 80px;
  text-align: left;
  font-family: 'KG HAPPY', sans-serif;
  margin-top: 5%;
 }

.storyH2-2{
  color: #ffffff;
  font-size: 80px;
  text-align: left;
  font-family: 'KG HAPPY', sans-serif;
  margin-top: 5%;
   margin-left: 5%;
}

.bH2 {
  color: #7eaeff;
  font-family: 'Cheddar Gothic Stencil', sans-serif;
  font-size: 75px;
}

.buildH {
  color: #000000;
  font-family: 'Cheddar Gothic Stencil', sans-serif;
  font-size: 50px;
}

.build {
  margin-top: 10%;
  color: black;
}

.storyCon2 {
  width: 50%;
  margin-top: 3%;
  margin-bottom: 5%;
  line-height: 1.6;
  font-weight: 400;
  font-size: 16px;
}

.greenBg {
  color: #ffffff;
  font-size: 22px;
  margin-top: 5%;
  
  width: fit-content;
  padding: 5px;
  background-size: cover;
}

.greenBg2 {
  color: #ffffff;
  font-size: 22px;
  
  width: fit-content;
  padding: 5px;
  background-size: cover;
}

.boxH {
  color: #000000;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 5px;
}

.box {
  background-color: black;
  padding: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 45%;
  border-radius: 5px;
}

.pic1 {
  width: 80%;
}

.pic2 {
  width: 80%;

}

.pic3 {
  width: 80%;

}

.pic4 {
  width: 80%;

}

.pic1Div {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  width: 20%;
}

.boxMain {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.boxMain2 {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-top: 2%;
}

.hoverPicsDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 5%;
}


.hoverPics {
  width: 400px;
  height: 324px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  background-image: url('./assets/no-hover.png');
  background-size: cover;
  border: none;
  transition: 0.5s;
}

.hoverPics:hover {
  width: 400px;
  height: 295px;
  padding: 20px;
  background-image: url('./assets/hover.png');
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: rgb(226, 226, 226);
  cursor: pointer;

}


/*.............. FAQ ..............*/


summary {
  font-size: 18px;
  background: rgba(255, 255, 255, 0);
  color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 2px solid white;
  letter-spacing: 1.5px;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}


@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 100%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 10%;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
  letter-spacing: 1px;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.man {
  width: 100%;
  height: 70vh;
  position: relative;
}

.paper {
  width: 100%;
}

.rmBox {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
  display: none;
}

.rmBox2 {
  background-color: rgb(112, 63, 63);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
  display: none;
}

.rmBox3 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox4 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox5 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox6 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox7 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox8 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox9 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox10 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
}

.rmBox11 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox12 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmBox13 {
  background-color: rgb(68, 68, 68);
  clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
}

.rmDown {
  color: black;
  font-size: 20px;
  margin-top: 5%;
  padding-bottom: 5%;
}

.rmT img {
  width: 50px;
  height: 50px;
}

.q img {
  width: 50px;
  height: 50px;
}

.rmT {
  font-size: 25px;
  margin-bottom: 2%;
  letter-spacing: 1px;
  display: flex;
  flex-flow: row nowrap;
}

.rmT2 {
  color: rgb(241, 171, 91);
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  text-align: center;
}

.q {
  font-size: 35px;
  margin-bottom: 2%;
  letter-spacing: 1px;
  display: flex;
  flex-flow: row nowrap;
}

.rmC {
  font-size: 20px;
  width: 100%;
  letter-spacing: 1px;
  color: white;
  text-align: left;
}

.audioPic {
  /* display: none;*/
  width: 100px;
  height: 100px;
  position: sticky;
  position: -webkit-sticky;
  margin-top: 30%;
  margin-left: -15%;
  z-index: 10000;
}

.speakerplace2 {
  margin-left: 15px;
  font-size: 18pt;
  margin-top: 12px;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  background-image: url('./audio/speakeroff.png');
  background-size: cover;
  width: 60px;
  height: 60px;
  border: 0;
  border: black 2px solid;
  z-index: 10000;

}

.speakerplace1 {
  margin-left: 15px;
  font-size: 18pt;
  margin-top: 12px;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  background-image: url('./audio/speakeron.jpg');
  background-size: cover;
  width: 60px;
  height: 60px;
  border: 0;
  border: black 2px solid;
  z-index: 10000;

}

.quotesAndAudio {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.rmDisplay {
  display: none;
}

.mapSet {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  z-index: 5;
  height: fit-content;
  position: relative;
  width: 90%;
  display: flex;
  margin-top: 5%;
}

.map {
  z-index: 9;
  width: 90%;
  position: absolute;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pin1 {
  width: 2%;
  margin-left: 57.5%;
  margin-top: 11%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.pin1:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;

}

.pin1:hover {
  filter: grayscale(100%);

}

.pin2:hover {
  filter: grayscale(100%);

}

.pinQuestion:hover {
  filter: grayscale(100%);

}

.pin3:hover {
  filter: grayscale(100%);

}

.pin4:hover {
  filter: grayscale(100%);

}

.pin5:hover {
  filter: grayscale(100%);

}

.pin6:hover {
  filter: grayscale(100%);

}

.pin7:hover {
  filter: grayscale(100%);

}

.pin8:hover {
  filter: grayscale(100%);

}

.pin9:hover {
  filter: grayscale(100%);

}

.pin10:hover {
  filter: grayscale(100%);

}

.pin11:hover {
  filter: grayscale(100%);

}

.pin12:hover {
  filter: grayscale(100%);

}

.pin13:hover {
  filter: grayscale(100%);

}

.pin14:hover {
  filter: grayscale(100%);

}

.pin15:hover {
  filter: grayscale(100%);

}

.pin16:hover {
  filter: grayscale(100%);

}


.pin2 {
  width: 2%;
  margin-left: 80.5%;
  margin-top: 21.5%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.pinQuestion {
  width: 2%;
  margin-left: 80.5%;
  margin-top: 24%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}


.pin2:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
}

.pinQuestion:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;

}

.pin3:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
}

.pin4:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
}

.pin5:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-top: 23%;
  z-index: 10000;
  width: 80%;
  display: block;
}

.pin6:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 80%;
  display: block;
}

.pin7:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  z-index: 10000;
  width: 80%;
  display: block;
  margin-top: 25%;
}

.pin8:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 60%;
  display: block;
  margin-top: 25%;
}

.pin9:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 80%;
  display: block;
}

.pin10:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
}

.pin11:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
}

.pin12:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;

}

.pin13:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
  margin-left: 15%;

}

.pin14:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
  margin-left: 10%;

}

.pin15:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 50%;
  display: block;
}

.pin16:hover+.rmBox1 {
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.897);
  box-shadow: 0px 5px;
  color: red;
  margin-bottom: 2%;
  z-index: 10000;
  width: 80%;
  display: block;
}

.rmBox1 {
  cursor: pointer;
  background-color: rgb(107, 185, 84);
  padding: 20px;
  box-shadow: 0px 5px;
  color: black;
  margin-bottom: 2%;
  width: 50%;
  display: none;
}

.pin3 {
  width: 2%;
  margin-left: 46%;
  margin-top: 23%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.pin4 {
  width: 2%;
  margin-left: 32%;
  margin-top: 23%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.pin5 {
  width: 2%;
  margin-left: 14%;
  margin-top: 18.5%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.pin6 {
  width: 2%;
  margin-left: 9.5%;
  margin-top: 18%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-10deg);
  cursor: pointer;

}

.pin7 {
  width: 2%;
  margin-left: 4.5%;
  margin-top: 18%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-10deg);
  cursor: pointer;

}

.pin8 {
  width: 2%;
  margin-right: 0.5%;
  margin-top: 15%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-20deg);
  cursor: pointer;

}

.pin9 {
  width: 2%;
  margin-right: 10.2%;
  margin-top: 15%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-17deg);
  cursor: pointer;

}

.pin10 {
  width: 2%;
  margin-right: 23.5%;
  margin-top: 18%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.pin11 {
  width: 2%;
  margin-right: 31.5%;
  margin-top: 19%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-17deg);
  cursor: pointer;

}

.pin12 {
  width: 2%;
  margin-right: 32.5%;
  margin-top: 25.5%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.pin13 {
  width: 2%;
  margin-right: 43.5%;
  margin-top: 21.5%;
  position: absolute;
  z-index: 1000;
  transform: rotate(1deg);
  cursor: pointer;

}

.pin14 {
  width: 2%;
  margin-right: 48.8%;
  margin-top: 20%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-10deg);
  cursor: pointer;

}

.pin15 {
  width: 2%;
  margin-right: 65.5%;
  margin-top: 20%;
  position: absolute;
  z-index: 1000;
  transform: rotate(-10deg);
  cursor: pointer;

}

.pin16 {
  width: 2%;
  margin-right: 86.5%;
  margin-top: 24.5%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}


@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.hide {
  display: none;
}

.myDIV:hover+.hide {
  display: block;
  color: red;
}

.leftCurtain {
  width: 52%;
  height: 100vh;
  background-color: red;
  z-index: 10000;
  background: url('./assets/leftcurtain.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.leftCurtainAnimation {
  transform: translateX(calc(-100%));
  transition: transform 1s ease-out;

}

.rightCurtainAnimation {
  transform: translateX(calc(100%));
  transition: transform 1s ease-out;

}

.rightCurtain {
  width: 52%;
  height: 100vh;
  background-color: yellow;
  z-index: 10000;
  background: url('./assets/rightcurtain.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mainCurtain {
  display: flex;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100;
  overflow-x: hidden;
}

.spaceCover {
  background-color: #000000;
  width: 100%;
  height: 100vh;
}

.spaceCoverAnimation {
  transition: transform 1s ease-out;
  transform: translateX(calc(-100%));

}

.enterBtn {
  z-index: 100000;
  margin-left: 45%;
  position: absolute;
  width: 10%;
  top: 49.5%;
  font-size: 27px;
  font-family: 'KG HAPPY', sans-serif;
  border: none;
  box-shadow: -7px 7px rgb(0, 0, 0);
}

.enterBtn:active {
  box-shadow: -5px 5px rgb(34, 34, 34);
}

.disappear {
  display: none;
}

.line-1 {
  position: relative;
  top: 50%;
  width: 95%;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 1%;
  margin-top: -1%;
  border-right: 2px solid rgba(255, 255, 255, .75);
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 95%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

.line-2 {
  position: relative;
  top: 50%;
  width: 48%;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 1%;
  margin-top: -1%;
  border-right: 2px solid rgba(255, 255, 255, .75);
}

.anim-typewriter2 {
  animation: typewriter2 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter2 {
  from {
    width: 0;
  }

  to {
    width: 48%;
  }
}

/*............................wheel.........................*/

.wrap-outer {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;

}

.wrapper {
  position: absolute;
  top: 45%;
  left: 50%;
  height: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  -webkit-animation: rotate 15s 0s infinite linear;
  animation: rotate 15s 0s infinite linear;
}

.out-wrap .circle-outer {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  background-color: #666666;
  border: 1px solid #ffffff;
}

.out-wrap .carriage {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  background-image: url('./assets/p1.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;
  z-index: 10000;

}

.out-wrap .carriage:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p1.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;
}

.out-wrap .carriage1 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  background-image: url('./assets/p2.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  z-index: 10000;

}

.out-wrap .carriage1:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10;
  content: "";
  background-image: url('./assets/p2.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;
  z-index: 10000;

}

.out-wrap .carriage2 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  z-index: 10000;
  background-image: url('./assets/p3.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.out-wrap .carriage2:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p3.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;

}

.out-wrap .carriage3 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  z-index: 10000;
  background-image: url('./assets/p4.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.out-wrap .carriage3:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p4.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;

}

.out-wrap .carriage4 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  z-index: 10000;
  background-image: url('./assets/p5.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.out-wrap .carriage4:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p5.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;

}

.out-wrap .carriage5 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  z-index: 10000;
  background-image: url('./assets/p6.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.out-wrap .carriage5:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p6.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;

}

.out-wrap .carriage6 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  z-index: 10000;
  background-image: url('./assets/p7.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.out-wrap .carriage6:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p7.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;

}

.out-wrap .carriage7 {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  z-index: 10000;
  background-image: url('./assets/p8.png') no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.out-wrap .carriage7:before {
  position: absolute;
  display: block;
  top: 3px;
  bottom: 10px;
  left: 3px;
  right: 3px;
  z-index: 10000;
  content: "";
  background-image: url('./assets/p8.png');
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  cursor: pointer;
}

.out-wrap .bar {
  position: absolute;
  left: 50%;
  top: 50%;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  background-color: #666666;
  height: 140px;
  width: 5px;
  z-index: -1;
}

.out-wrap:nth-child(1) .circle-outer {
  transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
}

.out-wrap:nth-child(2) .circle-outer {
  transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
}

.out-wrap:nth-child(3) .circle-outer {
  transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
}

.out-wrap:nth-child(4) .circle-outer {
  transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
}

.out-wrap:nth-child(5) .circle-outer {
  transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
}

.out-wrap:nth-child(6) .circle-outer {
  transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
}

.out-wrap:nth-child(7) .circle-outer {
  transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
}

.out-wrap:nth-child(8) .circle-outer {
  transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
}

.out-wrap:nth-child(1) .carriage {
  transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-1 15s 0s infinite linear;
  animation: counter-rotate-1 15s 0s infinite linear;
}

.out-wrap:nth-child(2) .carriage1 {
  transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-2 15s 0s infinite linear;
  animation: counter-rotate-2 15s 0s infinite linear;
}

.out-wrap:nth-child(3) .carriage2 {
  transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-3 15s 0s infinite linear;
  animation: counter-rotate-3 15s 0s infinite linear;
}

.out-wrap:nth-child(4) .carriage3 {
  transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-4 15s 0s infinite linear;
  animation: counter-rotate-4 15s 0s infinite linear;
}

.out-wrap:nth-child(5) .carriage4 {
  transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-5 15s 0s infinite linear;
  animation: counter-rotate-5 15s 0s infinite linear;
}

.out-wrap:nth-child(6) .carriage5 {
  transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-6 15s 0s infinite linear;
  animation: counter-rotate-6 15s 0s infinite linear;
}

.out-wrap:nth-child(7) .carriage6 {
  transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-7 15s 0s infinite linear;
  animation: counter-rotate-7 15s 0s infinite linear;
}

.out-wrap:nth-child(8) .carriage7 {
  transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
  -webkit-animation: counter-rotate-8 15s 0s infinite linear;
  animation: counter-rotate-8 15s 0s infinite linear;
}

.out-wrap:nth-child(1) .bar {
  transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
}

.out-wrap:nth-child(2) .bar {
  transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
}

.out-wrap:nth-child(3) .bar {
  transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
}

.out-wrap:nth-child(4) .bar {
  transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
}

.out-wrap:nth-child(5) .bar {
  transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
}

.out-wrap:nth-child(6) .bar {
  transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
}

.out-wrap:nth-child(7) .bar {
  transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
}

.out-wrap:nth-child(8) .bar {
  transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
}

.circle-center {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 50%;
  top: 45%;
  border-radius: 50%;
  background-color: #666666;
  z-index: 20;
  border: 1px solid #000000;
  transform: translate(-50%, -50%);
}

.circle-ring {
  position: absolute;
  height: 283px;
  width: 283px;
  left: 50%;
  top: 45%;
  border-radius: 50%;
  z-index: 1;
  background-color: #e2262600;
  border: 5px solid #ffffff;
  transform: translate(-50%, -50%);
}

.circle-ring:before {
  display: block;
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
  border: 1px solid #e23d3d00;
  border-radius: 50%;
  z-index: -3;
  background-color: #bfeaf200;
  content: "";
}

@-webkit-keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes counter-rotate-1 {
  from {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-1 {
  from {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-2 {
  from {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-2 {
  from {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-3 {
  from {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-3 {
  from {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-4 {
  from {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-4 {
  from {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-5 {
  from {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-5 {
  from {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-6 {
  from {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-6 {
  from {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-7 {
  from {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-7 {
  from {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
  }
}

@-webkit-keyframes counter-rotate-8 {
  from {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
  }
}

@keyframes counter-rotate-8 {
  from {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
  }
}

/*..............scratchpad...............*/

.scratchpad {
  width: 450px;
  height: 445px;
  border: solid 10px #FFFFFF;
  margin: 0 auto;
}

body {
  background: #efefef;
}

.scratch-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.promo-container {
  background: #FFF;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Open Sans', Arial, Sans-serif;
  color: #333;
  font-size: 16px;
  margin-top: 20px;
}

.btn {
  background: #56CFD2;
  color: #FFF;
  padding: 10px 25px;
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radiuss: 3px;
}

@media only screen and (max-width : 480px) {
  .scratchpad {
    width: 400px;
    height: 396px;
  }

  .scratch-container {
    width: 400px !important;
  }
}

@media only screen and (max-width : 320px) {
  .scratchpad {
    width: 290px;
    height: 287px;
  }

  .scratch-container {
    width: 290px !important;
  }
}

/*..............scratchpad...............*/
body {
  padding: 20px 0;
}

.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  z-index: 10000000;

}

.canvas {
  position: absolute;
   z-index: 10000000;
  margin-left: -350px;
}

.form {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  text-align: justify;
  background-size: contain;
  background-repeat: no-repeat;
   width: 600px;
}

.paperPink{
   object-fit: contain;
   width: 100%;
}

#container {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;

}

#card {
  width: 100%;
  position: relative;
  user-select: none;
}

img,
canvas {
  border-radius: 10px;
}

#card:active {
  transform: scale(1.05);
}

.blodDiv {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
}


.blodDiv img {
  width: 80%;
  cursor: pointer;
}

.comment {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  padding: 15px;
  background-color: #3d3d3dcb;
  border: none;
  border-radius: 5px;
  text-align: center;
  color: white;
  left: 2px;
}

.devil img {
  width: 10%;
}

.devil {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 3%;
}

.msg {
  color: white;
  font-size: 22px;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.back {
 width: 50px;
 height: 50px;
 right: 5%;
 margin-left: 2%;
 bottom: 0;
 padding-top: 2%;
 position:sticky;
 opacity: 0.8;
 transition: transform .2s;
 cursor: pointer;
}

.back:hover{
  transform: scale(1.1);
}

.afterClick{
  opacity: 0.5;
}


/*.................... Scratch Card ....................*/

 
.container {
  position: relative;
  width:100%;
   margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  -o-user-select: none;
  user-select: none;
 
}

.canvas {
  position: absolute;
  top: 0;
}

h2,h1{
    text-align: center;
}
.form {
  padding: 20px;
 
}
 
.videoBg{
   display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.vidCont {
   object-fit: contain;

}

.vidCont2 {
  object-fit: contain;
  margin-top: 5%;
}
/* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */

.puzzleBid{
  width: 30%;
  margin-right: 30px;
}

.bidArea2{
  color: rgb(116, 116, 116);
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.bidArea3{
  color: rgb(255, 255, 255);
  font-size: 24px;
  text-align: center;
}

.bidArea2Dis{
  color: rgb(78, 78, 78);
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  cursor: no-drop;

}

.bidArea3Dis{
  color: rgb(134, 134, 134);
  font-size: 24px;
  text-align: center;
  cursor: no-drop;

}

.split{
  width: 1px;
  height: 100%;
  background-color: #333;
}

.bidArea1{
  color: white;
  font-size: 55px;
}

.bidArea{
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: 30px;
}

.biddingMain{
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 6%;
  
}

.bidDetails{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: 12vh;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;

}

input {
  padding: 10px;
  border-radius: 10px;
  border: none;
}

.maxCon3{
font-size: 20px;
}

.amountSection{
  margin-top: 22px;
  display: flex;
}

.bidBtn{
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;

}

.bidBtnDis{
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;
  color: rgb(153, 153, 153);
  cursor: no-drop;

}

.bidMain{
  background-color: #000000;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
  overflow: scroll;
  overflow-x: hidden;
}

.bidT{
  color: white;
  text-align: center;
  font-size: 60px;
  font-family: 'KG HAPPY', sans-serif;
  margin-top: 50px;
}

.tokenIds {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  margin-bottom: 10%;
}

.tokenIdsMint {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  margin-bottom: 3%;
 }

 .tokenIdsMint2 {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: column wrap;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  margin-bottom: 3%;
 }

 .piece{
  width: 30%;
 }

.tokenIdsMintH{
  color: white;
  font-size: 25px;
  text-decoration: underline;
  text-shadow: #b8b8b83d 3px 5px 2px; 
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

.tokenIds2 {
  display: flex;
  justify-content: center;
  color: white;
  flex-flow: row wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15%;
}

.flip-card {
  background-color: transparent;
  width: 150px;
  perspective: 1000px;
  background-color: #e0706c;
  height: fit-content;

  margin-top: 10%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btnCenter {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.flip-card-front {
  /* background-color: #bbb;*/
  color: black;
}

.flip-card-back {
  /*background-color: #2980b9;*/
  color: white;
  transform: rotateY(180deg);
}

.unstakeImg {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;

}

.images_show {
  width: 90%;
}

.images_show3 {
  width: 90%;
  -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
  filter: blur(2px);
}

.images_show2 {
  width: 90%;
}

.stake2 {
  display: block;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 10px;
  border: none;
  width: 85px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.stake3 {
  display: block;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 10px;
  border: none;
  width: 85px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;
}

.stake3Dis {
  display: block;
  background-color: rgb(133, 133, 133);
  color: rgb(87, 87, 87);
  border-radius: 10px;
  border: none;
  width: 85px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;
  cursor: no-drop;

}


.elementName2 {
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.elementName3 {
  font-weight: 500;
  color: rgb(133, 133, 133);
  font-size: 20px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: fit-content;
  cursor: pointer;
  border: none;
}

.closeNote:hover,
.closeNote:focus {
  color: rgb(71, 70, 70);
}

.web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }

.counterBlock{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.previousBid{
  display: flex;
  justify-content: center;
  margin-top: 20px;
 }

.previousNumber{
  color: rgb(204, 204, 204);
  font-size: 18px;
  margin-left: 30px;
 }

 .previousAddress{
  color: rgb(204, 204, 204);
  font-size: 18px;
  margin-right: 30px;
 
}

.connectbtn2{
  padding: 0;
  border: none;
  background-color: #00346700;
}

.connectbtn2:hover{
  color: #e4e4e4;
}

.bidImg{
  width: 350px;
  height: 350px;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.bidSuccess{
  text-align: center;
  margin-top: 40px;
  color: rgb(206, 206, 206);
  font-size: 50px;
}

.back2{
  background-color: white;
  color: black;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: none;
  margin-top: 20px;
  font-size: 20px;
 }

 .next{
  color: rgb(192, 192, 192);
  margin-top: 20px;
 }

 .nextDis{
  color: rgb(128, 128, 128);
  margin-top: 20px;
  cursor: no-drop;

 }

 .winner{
  text-align: center;
  font-size: 55px; 
  color: white;
  text-shadow: #b8b8b83d 3px 5px 2px; 
}

.mintMain{
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  display: flex;
  margin-top: 20px;
}

.mintBtns{
  background-color: white;
  color: black;
  font-size: 20px;
  padding: 10px;
  width: 350px;
  box-shadow: -5px 5px rgb(170, 170, 170);
  transition: transform .5s; /* Animation */
  border: none;
}

.mintBtns:hover{
  transform: scale(1.2);
}

.noteMain{
  background-color: #000000;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 40%;
  height: 40vh;
  top: 50%;
  left: 50%;
  z-index: 100000;
  overflow: scroll;
  overflow-x: hidden;
  border: white 2px solid;
  box-shadow: -5px 5px rgb(194, 194, 194);
 
}

.notOwner{
  color: white;
  text-align: center;
  font-size: 25px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-top: 110px;
}

.count{
  text-align: left;
  color: white;
  font-size: 20px;
  font-style: italic;
}

.mintCollect{
  color: black;
  font-size: 30px;
  border-radius: 10px;
  box-shadow: -5px 5px rgb(100, 100, 100);
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 50px;
  width: 200px;
}

.mintCollectDis{
  color: rgb(104, 104, 104);
  font-size: 30px;
  border-radius: 10px;
  box-shadow: -5px 5px rgb(100, 100, 100);
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 50px;
  width: 200px;
  cursor: no-drop;
}

.mintCollect:hover{
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.nickName{
  border: 4px rgb(78, 78, 78) solid ;
}

.login{
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.nicknameAndImg{
  display: flex;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
}
}

@media screen and (min-width: 1920px) {
 
  .concatenate{
    color: rgb(146, 146, 146);
    font-size: 14px;
    margin-top: 20px;
  }
  
  textarea{
    width: 100%;
    height: 30vh;
    border: none;
    background-color: black;
    color: white;
    overflow-y: scroll;
  }
  
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(204, 204, 204);
    font-size: 18px;
  }

  a{
    font-size: 18px;
    color: rgb(204, 204, 204);
    text-decoration: none;
  }

  a:hover {
    font-size: 18px;
    color: #888;
    text-decoration: underline;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f200;}
   
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff00;
    color: white;
    text-align: center;
    font-weight: 100;
  }
 
  .rightAbout{
    display: none;
  }
  .rightMobile{
    display: none;
  }
  .mainPicMobile{
    display: none;
  }
  .submitNickname{
    color: whitesmoke;
    font-size: large;
    font-style: italic;
  }
  #js-canvas-mobile{
    display: none;
  }
  .intro2{
    display: none;
  }
  .storyConMob{
    display: none;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 27px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .inputAndSubmit {
    display: flex;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inputAndSubmit img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 70px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 1%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 30px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 75px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 120px;
    text-align: left;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 20px;
  
  }
  
  .mainPic {
    width: 35%;
  }
  
  .introMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  
  }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 27px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 27px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 28%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 70%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 350px;
  }
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 350px;
  }
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 70px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 53px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 28px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 115px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 550px;
    height: 445px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 550px;
    height: 405px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 27px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 1.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 40vh;
    position: relative;
   }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 70px;
    height: 70px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 30px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -15%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 45%;
    position: absolute;
    width: 10%;
    top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 95%;
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 1150px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 760px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
   }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
   
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
  
  }
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 730px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 100%;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
  }
  
  .devil img {
    width: 10%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 50px;
   height: 50px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   padding-top: 2%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
    margin-left: -400px;

  }
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 30%;
    margin-right: 30px;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 35px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 35px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 65px;
  }
  
  .bidArea{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 30px;
  }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    
  }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 25px;
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    font-size: 25px;
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
    font-size: 25px;

  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 80px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .images_show {
    width: 90%;
  }
  
  .images_show3 {
    width: 90%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 90%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    font-size: 25px;
  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 45px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    padding: 0;
    border: none;
    background-color: #00346700;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 500px;
    height: 500px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 70px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
    font-size: 35px;
   }
  
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
    font-size: 23px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
    font-size: 23px;

   }
  
   .winner{
    text-align: center;
    font-size: 55px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 30px;
    padding: 10px;
    width: 400px;
    box-shadow: -5px 5px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 40%;
    height: 25vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
    font-size: 23px;
  }
  
  .login{
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .concatenate{
    color: rgb(146, 146, 146);
    font-size: 16px;
    margin-top: 20px;
  }
  
  textarea{
    width: 100%;
    height: 30vh;
    border: none;
    background-color: black;
    color: white;
    overflow-y: scroll;
  }
  
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(204, 204, 204);
    font-size: 20px;
  }

  a{
    font-size: 20px;
    color: rgb(204, 204, 204);
    text-decoration: none;
  }

  a:hover {
    font-size: 20px;
    color: #888;
    text-decoration: underline;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f200;}
   
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff00;
    color: white;
    text-align: center;
    font-weight: 100;
  }
 
  .rightAbout{
    display: none;
  }
  .rightMobile{
    display: none;
  }
  .submitNickname{
    color: whitesmoke;
    font-size: large;
    font-style: italic;
  }
  #js-canvas-mobile{
    display: none;
  }
  .mainPicMobile{
    display: none;
  }
  .intro2{
    display: none;
  }
  .storyConMob{
    display: none;
  }
  
  .osPic {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 38px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .inputAndSubmit {
    display: flex;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inputAndSubmit img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 150px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 1%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 42px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 90px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 160px;
    text-align: left;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 20px;
  
  }
  
  .mainPic {
    width: 35%;
  }
  
  .introMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  
  }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 33%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 70%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 450px;
  }
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 450px;
  }

  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 100px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 110px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 60px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 33px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 140px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 750px;
    height: 607px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 750px;
    height: 552px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 36px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 1.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 34px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 40vh;
    position: relative;
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 90px;
    height: 90px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 50px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 40px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -15%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 45%;
    position: absolute;
    width: 10%;
    top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 95%;
    color: rgb(255, 255, 255);
    font-size: 40px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 1525px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 42px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 1050px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
   }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
   
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
  
  }
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 730px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 100%;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
    font-size: 40px;
  }
  
  .devil img {
    width: 10%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 100px;
   height: 100px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   padding-top: 2%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
    margin-left: -600px;

  }
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 30%;
    margin-right: 30px;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 35px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 45px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 35px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 45px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 75px;
  }
  
  .bidArea{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 30px;
  }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    
  }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 30px;
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    font-size: 30px;
 
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
    font-size: 30px;

  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 80px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .images_show {
    width: 90%;
  }
  
  .images_show3 {
    width: 90%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 90%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    font-size: 25px;
  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 45px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    padding: 0;
    border: none;
    background-color: #00346700;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 500px;
    height: 500px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 70px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
    font-size: 35px;
   }
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
    font-size: 30px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
    font-size: 30px;

   }
  
   .winner{
    text-align: center;
    font-size: 55px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 80px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 45px;
    padding: 10px;
    width: 500px;
    box-shadow: -5px 5px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 40%;
    height: 25vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
    font-size: 35px;
  }
  
  .login{
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 3840px) {
  .concatenate{
    color: rgb(146, 146, 146);
    font-size: 14px;
    margin-top: 20px;
  }
  
  textarea{
    width: 100%;
    height: 30vh;
    border: none;
    background-color: black;
    color: white;
    overflow-y: scroll;
  }
  
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(204, 204, 204);
    font-size: 18px;
  }

  a{
    font-size: 18px;
    color: rgb(204, 204, 204);
    text-decoration: none;
  }

  a:hover {
    font-size: 18px;
    color: #888;
    text-decoration: underline;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f200;}
   
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff00;
    color: white;
    text-align: center;
    font-weight: 100;
  }
 
  .rightAbout{
    display: none;
  }
  .mainPicMobile{
    display: none;
  }
  #js-canvas-mobile{
    display: none;
  }
  .submitNickname{
    color: whitesmoke;
    font-size: large;
    font-style: italic;
  }
  .rightMobile{
    display: none;
  }
  .storyConMob{
    display: none;
  }
 
  .intro2{
    display: none;
  }
  
  .osPic {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
  
  .osPicB {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  
  .osPicB:hover {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-image: url('./assets/discord.png');
  }
  
  .osPic2 {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 60px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }
  
  .boxWrap2 {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Rm {
    background-color: #000000;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
    height: 160vh;
  }
  
  .boxWrap3B {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap2Main {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .secretsMain {
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .scratchMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  }
  
  .secrets {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6894355154171043) 0%, rgba(0, 0, 0, 0.8210881764815301) 43%, rgba(0, 0, 0, 0.9303318739605217) 97%), url('./assets/cos.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
  
  }
  
  .inputAndSubmit {
    display: flex;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .inputAndSubmit img {
    width: 100px;
    height: 100px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  .inputAndSubmit button {
    background-color: rgba(0, 0, 255, 0);
    border: none;
  }
  
  .secretsT {
    font-size: 200px;
    text-align: center;
    font-family: 'Liquidism part 2', sans-serif;
    padding-top: 1%;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(122, 122, 122));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .boxWrap3Main {
    background-color: #003467;
  }
  
  .boxWrap3 {
    background-color: #003467;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.2;
  }
  
  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  
  .boxWrapB {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    color: black;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .bH {
    font-size: 25px;
    color: #7eaeff;
    font-weight: 500;
    padding-top: 5%;
  }
  
  .noteB {
    font-size: 18px;
    color: #7eaeff;
    margin-top: 5%;
  }
  
  .storyCon {
    color: white;
    font-size: 60px;
    margin-top: 5%;
    letter-spacing: 1px;
    text-align: justify;
  }
  
  .storyCon-1 {
    color: white;
   
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bigWord {
    font-size: 130px;
    color: rgb(255, 255, 255);
  }
  
  .storyConM {
    color: black;
    font-size: 21px;
    margin-top: 8%;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 100%;
    text-align: center;
   }
  
  .storyRight2-2 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .storyRight2-2-2 {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  
  .about1 {
    margin-right: 3.5%;
  }
  
  .about2 {
    margin-top: 1.5%;
  }
  
  .storyRight2 {
    width: 100%;
  }
  
  .storyRight3 {
    width: 60%;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 230px;
    text-align: left;
    z-index: 1000;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    letter-spacing: 5px;
    font-family: 'KG HAPPY', sans-serif;
  
  }
  
  .introMain2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: left;
    overflow: hidden;
  }
  
  .intro2 {
    color: rgb(255, 255, 255);
    font-size: 20px;
  
  }
  
  .mainPic {
    width: 35%;
  }
  
  .introMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .discord {
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  
  }
  
  .discord:hover {
    background-color: rgb(83, 83, 83);
    cursor: pointer;
  }
  
  .discordBlue {
    margin-right: 20px;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.5;
    transition: transform .2s;
  }
  
  .discordBlue:hover {
    background-color: #7eaeff;
    cursor: pointer;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: black;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    transition: transform .2s;
  
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
    margin-right: 2%;
  
  }
  
  .connectbtn {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
    font-size: 52px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
  }
  
  .connectbtnActive {
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e4e4;
    font-size: 52px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(54, 54, 54);
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
     z-index: 100;
  }
  
  .button:focus {
    background-color: rgb(158, 60, 60);
  }
  
  .connectbtnBlue {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    transition: 0.6;
  }
  
  .connectbtnActiveB {
    margin-left: 10px;
    margin-right: 10px;
    color: #7eaeff;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    border: solid #7eaeff 1px;
    transition: 0.6;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectbtn:hover {
    background-color: rgb(54, 54, 54);
    color: #e4e4e4;
  }
  
  .connectbtnBlue:hover {
    background-color: #7eaeff;
    color: black;
  }
  
  .stickyHand {
    width: 33%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 70%;
    margin-top: -40%;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
   }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -10px 10px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 10px 10px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 60px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 700px;
  }
  
  .btnfos-0-3-Dis {
    margin-top: 20px;
    font-size: 60px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    width: 700px;
  }
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #ffffff;
    font-size: 130px;
     text-shadow: 4px 3px 0 #474747;
     margin-top: auto;
     margin-bottom: auto;
     justify-content: center;
     display: flex;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    background-color: rgba(255, 255, 255, 0.315);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: black;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 160px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-bottom: 5px;
     text-shadow: 4px 3px 0 #383838;
    letter-spacing: 1px;
    color: white;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background-color: black;
    position: static;
    margin-top: 5%;
    padding: 60px;
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 90px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-decoration: underline;
    font-style: italic;
  }
  
  .copyright2 {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 50px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-top: 1%;
  }
  
  .footer2 {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rmF {
    color: black;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .footerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: -8%;
    padding-left: 7%;
    padding-right: 7%;
  }
  
  .footerDetails2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 7%;
    padding-bottom: 2.5%;
  }
  
  .footerDetails3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 14%;
    padding-bottom: 2.5%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .logo {
    width: 250px;
    height: 69px;
  }
  
  .glassAndCon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .glass {
    width: 75%;
  }
  
  .light {
    background-color: black;
    min-height: 100vh;
    height: max-content;
  }
  
  .pagebtn {
    background-color: rgba(0, 0, 128, 0);
    border: none;
    color: black;
    font-weight: 500;
  }
  
  .storyH2 {
    color: #ffffff;
    font-size: 190px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
   }
  
  .storyH2-2{
    color: #ffffff;
    font-size: 80px;
    text-align: left;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 5%;
     margin-left: 5%;
  }
  
  .bH2 {
    color: #7eaeff;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 75px;
  }
  
  .buildH {
    color: #000000;
    font-family: 'Cheddar Gothic Stencil', sans-serif;
    font-size: 50px;
  }
  
  .build {
    margin-top: 10%;
    color: black;
  }
  
  .storyCon2 {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 5%;
    line-height: 1.6;
    font-weight: 400;
    font-size: 16px;
  }
  
  .greenBg {
    color: #ffffff;
    font-size: 22px;
    margin-top: 5%;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .greenBg2 {
    color: #ffffff;
    font-size: 22px;
    
    width: fit-content;
    padding: 5px;
    background-size: cover;
  }
  
  .boxH {
    color: #000000;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 5px;
  }
  
  .box {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 45%;
    border-radius: 5px;
  }
  
  .pic1 {
    width: 80%;
  }
  
  .pic2 {
    width: 80%;
  
  }
  
  .pic3 {
    width: 80%;
  
  }
  
  .pic4 {
    width: 80%;
  
  }
  
  .pic1Div {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: 20%;
  }
  
  .boxMain {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  
  .boxMain2 {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin-top: 2%;
  }
  
  .hoverPicsDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
  
  
  .hoverPics {
    width: 1100px;
    height: 891px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    background-image: url('./assets/no-hover.png');
    background-size: cover;
    border: none;
    transition: 0.5s;
  }
  
  .hoverPics:hover {
    width: 1100px;
    height: 810px;
    padding: 20px;
    background-image: url('./assets/hover.png');
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 55px;
    background: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    margin-bottom: 4rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid white;
    letter-spacing: 1.5px;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 50px;
    letter-spacing: 1px;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
  
  }
  
  .man {
    width: 100%;
    height: 40vh;
    position: relative;
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBox {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 10%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox2 {
    background-color: rgb(112, 63, 63);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    display: none;
  }
  
  .rmBox3 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox4 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox5 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox6 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox7 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox8 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox9 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox10 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
  }
  
  .rmBox11 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox12 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 7%, 85% 0, 85% 100%, 0 90%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmBox13 {
    background-color: rgb(68, 68, 68);
    clip-path: polygon(0 0, 85% 17%, 85% 93%, 0 100%);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
  }
  
  .rmDown {
    color: black;
    font-size: 20px;
    margin-top: 5%;
    padding-bottom: 5%;
  }
  
  .rmT img {
    width: 120px;
    height: 120px;
  }
  
  .q img {
    width: 50px;
    height: 50px;
  }
  
  .rmT {
    font-size: 80px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmT2 {
    color: rgb(241, 171, 91);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    text-align: center;
  }
  
  .q {
    font-size: 35px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .rmC {
    font-size: 60px;
    width: 100%;
    letter-spacing: 1px;
    color: white;
    text-align: left;
  }
  
  .audioPic {
    /* display: none;*/
    width: 100px;
    height: 100px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 30%;
    margin-left: -15%;
    z-index: 10000;
  }
  
  .speakerplace2 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeroff.png');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .speakerplace1 {
    margin-left: 15px;
    font-size: 18pt;
    margin-top: 12px;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
    background-image: url('./audio/speakeron.jpg');
    background-size: cover;
    width: 60px;
    height: 60px;
    border: 0;
    border: black 2px solid;
    z-index: 10000;
  
  }
  
  .quotesAndAudio {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .rmDisplay {
    display: none;
  }
  
  .mapSet {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 5;
    height: fit-content;
    position: relative;
    width: 90%;
    display: flex;
    margin-top: 5%;
  }
  
  .map {
    z-index: 9;
    width: 90%;
    position: absolute;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .pin1 {
    width: 2%;
    margin-left: 57.5%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .pin1:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin1:hover {
    filter: grayscale(100%);
  
  }
  
  .pin2:hover {
    filter: grayscale(100%);
  
  }
  
  .pinQuestion:hover {
    filter: grayscale(100%);
  
  }
  
  .pin3:hover {
    filter: grayscale(100%);
  
  }
  
  .pin4:hover {
    filter: grayscale(100%);
  
  }
  
  .pin5:hover {
    filter: grayscale(100%);
  
  }
  
  .pin6:hover {
    filter: grayscale(100%);
  
  }
  
  .pin7:hover {
    filter: grayscale(100%);
  
  }
  
  .pin8:hover {
    filter: grayscale(100%);
  
  }
  
  .pin9:hover {
    filter: grayscale(100%);
  
  }
  
  .pin10:hover {
    filter: grayscale(100%);
  
  }
  
  .pin11:hover {
    filter: grayscale(100%);
  
  }
  
  .pin12:hover {
    filter: grayscale(100%);
  
  }
  
  .pin13:hover {
    filter: grayscale(100%);
  
  }
  
  .pin14:hover {
    filter: grayscale(100%);
  
  }
  
  .pin15:hover {
    filter: grayscale(100%);
  
  }
  
  .pin16:hover {
    filter: grayscale(100%);
  
  }
  
  
  .pin2 {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pinQuestion {
    width: 2%;
    margin-left: 80.5%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  .pin2:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pinQuestion:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin3:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin4:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin5:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-top: 23%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin6:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin7:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    z-index: 10000;
    width: 80%;
    display: block;
    margin-top: 25%;
  }
  
  .pin8:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 60%;
    display: block;
    margin-top: 25%;
  }
  
  .pin9:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .pin10:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin11:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin12:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  
  }
  
  .pin13:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 15%;
  
  }
  
  .pin14:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
    margin-left: 10%;
  
  }
  
  .pin15:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 50%;
    display: block;
  }
  
  .pin16:hover+.rmBox1 {
    cursor: pointer;
    background-color: rgba(41, 41, 41, 0.897);
    box-shadow: 0px 5px;
    color: red;
    margin-bottom: 2%;
    z-index: 10000;
    width: 80%;
    display: block;
  }
  
  .rmBox1 {
    cursor: pointer;
    background-color: rgb(107, 185, 84);
    padding: 20px;
    box-shadow: 0px 5px;
    color: black;
    margin-bottom: 2%;
    width: 50%;
    display: none;
  }
  
  .pin3 {
    width: 2%;
    margin-left: 46%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin4 {
    width: 2%;
    margin-left: 32%;
    margin-top: 23%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin5 {
    width: 2%;
    margin-left: 14%;
    margin-top: 18.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin6 {
    width: 2%;
    margin-left: 9.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin7 {
    width: 2%;
    margin-left: 4.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin8 {
    width: 2%;
    margin-right: 0.5%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-20deg);
    cursor: pointer;
  
  }
  
  .pin9 {
    width: 2%;
    margin-right: 10.2%;
    margin-top: 15%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin10 {
    width: 2%;
    margin-right: 23.5%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin11 {
    width: 2%;
    margin-right: 31.5%;
    margin-top: 19%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-17deg);
    cursor: pointer;
  
  }
  
  .pin12 {
    width: 2%;
    margin-right: 32.5%;
    margin-top: 25.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .pin13 {
    width: 2%;
    margin-right: 43.5%;
    margin-top: 21.5%;
    position: absolute;
    z-index: 1000;
    transform: rotate(1deg);
    cursor: pointer;
  
  }
  
  .pin14 {
    width: 2%;
    margin-right: 48.8%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin15 {
    width: 2%;
    margin-right: 65.5%;
    margin-top: 20%;
    position: absolute;
    z-index: 1000;
    transform: rotate(-10deg);
    cursor: pointer;
  
  }
  
  .pin16 {
    width: 2%;
    margin-right: 86.5%;
    margin-top: 24.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .hide {
    display: none;
  }
  
  .myDIV:hover+.hide {
    display: block;
    color: red;
  }
  
  .leftCurtain {
    width: 52%;
    height: 100vh;
    background-color: red;
    z-index: 10000;
    background: url('./assets/leftcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .leftCurtainAnimation {
    transform: translateX(calc(-100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtainAnimation {
    transform: translateX(calc(100%));
    transition: transform 1s ease-out;
  
  }
  
  .rightCurtain {
    width: 52%;
    height: 100vh;
    background-color: yellow;
    z-index: 10000;
    background: url('./assets/rightcurtain.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .mainCurtain {
    display: flex;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100;
    overflow-x: hidden;
  }
  
  .spaceCover {
    background-color: #000000;
    width: 100%;
    height: 100vh;
  }
  
  .spaceCoverAnimation {
    transition: transform 1s ease-out;
    transform: translateX(calc(-100%));
  
  }
  
  .enterBtn {
    z-index: 100000;
    margin-left: 45%;
    position: absolute;
    width: 10%;
    top: 49.5%;
    font-size: 27px;
    font-family: 'KG HAPPY', sans-serif;
    border: none;
    box-shadow: -7px 7px rgb(0, 0, 0);
  }
  
  .enterBtn:active {
    box-shadow: -5px 5px rgb(34, 34, 34);
  }
  
  .disappear {
    display: none;
  }
  
  .line-1 {
    position: relative;
    top: 50%;
    width: 95%;
    color: rgb(255, 255, 255);
    font-size: 55px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 2100px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    color: rgb(255, 255, 255);
    font-size: 60px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 1%;
    margin-top: -1%;
    border-right: 2px solid rgba(255, 255, 255, .75);
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 1470px;
    }
  }
  
  /*............................wheel.........................*/
  
  .wrap-outer {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
   }
  
  .wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapper {
    -webkit-animation: rotate 15s 0s infinite linear;
    animation: rotate 15s 0s infinite linear;
  }
  
  .out-wrap .circle-outer {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: #666666;
    border: 1px solid #ffffff;
  }
  
  .out-wrap .carriage {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p1.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p1.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .carriage1 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    background-image: url('./assets/p2.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage1:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10;
    content: "";
    background-image: url('./assets/p2.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .out-wrap .carriage2 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p3.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage2:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p3.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage3 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p4.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage3:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p4.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage4 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p5.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage4:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p5.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage5 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p6.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage5:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p6.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage6 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p7.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage6:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p7.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  
  }
  
  .out-wrap .carriage7 {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 50%;
    z-index: 10000;
    background-image: url('./assets/p8.png') no-repeat center center fixed;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  
  .out-wrap .carriage7:before {
    position: absolute;
    display: block;
    top: 3px;
    bottom: 10px;
    left: 3px;
    right: 3px;
    z-index: 10000;
    content: "";
    background-image: url('./assets/p8.png');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  
  .out-wrap .bar {
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    background-color: #666666;
    height: 140px;
    width: 5px;
    z-index: -1;
  }
  
  .out-wrap:nth-child(1) .circle-outer {
    transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(2) .circle-outer {
    transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(3) .circle-outer {
    transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(4) .circle-outer {
    transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(5) .circle-outer {
    transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(6) .circle-outer {
    transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(7) .circle-outer {
    transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(8) .circle-outer {
    transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px);
  }
  
  .out-wrap:nth-child(1) .carriage {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-1 15s 0s infinite linear;
    animation: counter-rotate-1 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(2) .carriage1 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-2 15s 0s infinite linear;
    animation: counter-rotate-2 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(3) .carriage2 {
    transform: translate(20px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-3 15s 0s infinite linear;
    animation: counter-rotate-3 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(4) .carriage3 {
    transform: translate(30px, 25px) translate(-50%, -50%) rotate(0deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-4 15s 0s infinite linear;
    animation: counter-rotate-4 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(5) .carriage4 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-5 15s 0s infinite linear;
    animation: counter-rotate-5 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(6) .carriage5 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-6 15s 0s infinite linear;
    animation: counter-rotate-6 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(7) .carriage6 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-7 15s 0s infinite linear;
    animation: counter-rotate-7 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(8) .carriage7 {
    transform: translate(0px, 25px) translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg);
    -webkit-animation: counter-rotate-8 15s 0s infinite linear;
    animation: counter-rotate-8 15s 0s infinite linear;
  }
  
  .out-wrap:nth-child(1) .bar {
    transform: translate(-50%, -50%) rotate(45deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(2) .bar {
    transform: translate(-50%, -50%) rotate(90deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(3) .bar {
    transform: translate(-50%, -50%) rotate(135deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(4) .bar {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(5) .bar {
    transform: translate(-50%, -50%) rotate(225deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(6) .bar {
    transform: translate(-50%, -50%) rotate(270deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(7) .bar {
    transform: translate(-50%, -50%) rotate(315deg) translate(0, -50%);
  }
  
  .out-wrap:nth-child(8) .bar {
    transform: translate(-50%, -50%) rotate(360deg) translate(0, -50%);
  }
  
  .circle-center {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    background-color: #666666;
    z-index: 20;
    border: 1px solid #000000;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring {
    position: absolute;
    height: 283px;
    width: 283px;
    left: 50%;
    top: 45%;
    border-radius: 50%;
    z-index: 1;
    background-color: #e2262600;
    border: 5px solid #ffffff;
    transform: translate(-50%, -50%);
  }
  
  .circle-ring:before {
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border: 1px solid #e23d3d00;
    border-radius: 50%;
    z-index: -3;
    background-color: #bfeaf200;
    content: "";
  }
  
  @-webkit-keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @-webkit-keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-1 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(45deg) translate(140px, 0px) rotate(-45deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-2 {
    from {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(90deg) translate(140px, 0px) rotate(-90deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-3 {
    from {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(135deg) translate(140px, 0px) rotate(-135deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-4 {
    from {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(180deg) translate(140px, 0px) rotate(-180deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-5 {
    from {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(225deg) translate(140px, 0px) rotate(-225deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-6 {
    from {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(270deg) translate(140px, 0px) rotate(-270deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-7 {
    from {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(315deg) translate(140px, 0px) rotate(-315deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @-webkit-keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  @keyframes counter-rotate-8 {
    from {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(0deg) translate(0px, 25px);
    }
  
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(140px, 0px) rotate(-360deg) rotate(-360deg) translate(0px, 25px);
    }
  }
  
  /*..............scratchpad...............*/
  
  .scratchpad {
    width: 450px;
    height: 445px;
    border: solid 10px #FFFFFF;
    margin: 0 auto;
  }
  
  body {
    background: #efefef;
  }
  
  .scratch-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
  
  .promo-container {
    background: #FFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', Arial, Sans-serif;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .btn {
    background: #56CFD2;
    color: #FFF;
    padding: 10px 25px;
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radiuss: 3px;
  }
  
  @media only screen and (max-width : 480px) {
    .scratchpad {
      width: 400px;
      height: 396px;
    }
  
    .scratch-container {
      width: 400px !important;
    }
  }
  
  @media only screen and (max-width : 320px) {
    .scratchpad {
      width: 290px;
      height: 287px;
    }
  
    .scratch-container {
      width: 290px !important;
    }
  }
  
  /*..............scratchpad...............*/
  body {
    padding: 20px 0;
  }
  
  .container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: 10000000;
   
  }
  
  .canvas {
    position: absolute;
     z-index: 10000000;
  
  }
  
  .form {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: justify;
    background-size: contain;
    background-repeat: no-repeat;
     width: 730px;
  }
  
  .paperPink{
     object-fit: contain;
     width: 100%;
  }
  
  #container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
  
  }
  
  #card {
    width: 100%;
    position: relative;
    user-select: none;
  }
  
  img,
  canvas {
    border-radius: 10px;
  }
  
  #card:active {
    transform: scale(1.05);
  }
  
  .blodDiv {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  
  .blodDiv img {
    width: 80%;
    cursor: pointer;
  }
  
  .comment {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    padding: 15px;
    background-color: #3d3d3dcb;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;
    left: 2px;
    font-size: 55px;
  }
  
  .devil img {
    width: 10%;
  }
  
  .devil {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 3%;
  }
  
  .msg {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .back {
   width: 150px;
   height: 150px;
   right: 5%;
   margin-left: 2%;
   bottom: 0;
   padding-top: 2%;
   position:sticky;
   opacity: 0.8;
   transition: transform .2s;
   cursor: pointer;
  }
  
  .back:hover{
    transform: scale(1.1);
  }
  
  .afterClick{
    opacity: 0.5;
  }
  
  
  /*.................... Scratch Card ....................*/
  
   
  .container {
    position: relative;
    width:100%;
     margin: 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
   
  }
  
  .canvas {
    position: absolute;
    top: 0;
    margin-left: -650px;

  }
  h2,h1{
      text-align: center;
  }
  .form {
    padding: 20px;
   
  }
   
  .videoBg{
     display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .vidCont {
     object-fit: contain;
  
  }
  
  .vidCont2 {
    object-fit: contain;
    margin-top: 5%;
  }
  /* Start https://www.cursors-4u.com */ * {cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1163.png), auto !important;} /* End https://www.cursors-4u.com */
  
  .puzzleBid{
    width: 30%;
    margin-right: 30px;
  }
  
  .bidArea2{
    color: rgb(116, 116, 116);
    font-size: 55px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bidArea3{
    color: rgb(255, 255, 255);
    font-size: 70px;
    text-align: center;
  }
  
  .bidArea2Dis{
    color: rgb(78, 78, 78);
    font-size: 55px;
    text-align: center;
    margin-bottom: 20px;
    cursor: no-drop;
  
  }
  
  .bidArea3Dis{
    color: rgb(134, 134, 134);
    font-size: 70px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  .split{
    width: 1px;
    height: 100%;
    background-color: #333;
  }
  
  .bidArea1{
    color: white;
    font-size: 95px;
  }
  
  .bidArea{
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: 30px;
  }
  
  .biddingMain{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 6%;
    
  }
  
  .bidDetails{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 12vh;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  
  }
  
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  
  .maxCon3{
  font-size: 50px;
  
  }
  
  .amountSection{
    margin-top: 22px;
    display: flex;
  }
  
  .bidBtn{
    margin-left: 20px;
    padding: 20px;
    border-radius: 10px;
    font-size: 50px;
 
  }
  
  .bidBtnDis{
    margin-left: 20px;
    padding: 20px;
    border-radius: 10px;
    color: rgb(153, 153, 153);
    cursor: no-drop;
    font-size: 50px;

  }
  
  .bidMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .bidT{
    color: white;
    text-align: center;
    font-size: 150px;
    font-family: 'KG HAPPY', sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }
  
  .tokenIdsMint {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .tokenIdsMint2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: column wrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 3%;
   }
  
   .piece{
    width: 30%;
   }
  
  .tokenIdsMintH{
    color: white;
    font-size: 25px;
    text-decoration: underline;
    text-shadow: #b8b8b83d 3px 5px 2px; 
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  
  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;
  
    margin-top: 10%;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }
  
  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  .images_show {
    width: 90%;
  }
  
  .images_show3 {
    width: 90%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
  }
  
  .images_show2 {
    width: 90%;
  }
  
  .stake2 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }
  
  .stake3 {
    display: block;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    font-size: 25px;
  }
  
  .stake3Dis {
    display: block;
    background-color: rgb(133, 133, 133);
    color: rgb(87, 87, 87);
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    cursor: no-drop;
  
  }
  
  
  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .elementName3 {
    font-weight: 500;
    color: rgb(133, 133, 133);
    font-size: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 70px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }
  
  .web3modal-modal-lightbox.web3modal-modal-lightbox { z-index: 100000; }
  
  .counterBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .previousBid{
    display: flex;
    justify-content: center;
    margin-top: 20px;
   }
  
  .previousNumber{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-left: 30px;
   }
  
   .previousAddress{
    color: rgb(204, 204, 204);
    font-size: 18px;
    margin-right: 30px;
   
  }
  
  .connectbtn2{
    padding: 0;
    border: none;
    background-color: #00346700;
  }
  
  .connectbtn2:hover{
    color: #e4e4e4;
  }
  
  .bidImg{
    width: 500px;
    height: 500px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  
  .bidSuccess{
    text-align: center;
    margin-top: 40px;
    color: rgb(206, 206, 206);
    font-size: 70px;
  }
  
  .back2{
    background-color: white;
    color: black;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: 20px;
    font-size: 20px;
    font-size: 35px;
   }
  
   .next{
    color: rgb(192, 192, 192);
    margin-top: 20px;
    font-size: 47px;
   }
  
   .nextDis{
    color: rgb(128, 128, 128);
    margin-top: 20px;
    cursor: no-drop;
    font-size: 47px;

   }
  
   .winner{
    text-align: center;
    font-size: 55px; 
    color: white;
    text-shadow: #b8b8b83d 3px 5px 2px; 
  }
  
  .mintMain{
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    display: flex;
    margin-top: 80px;
  }
  
  .mintBtns{
    background-color: white;
    color: black;
    font-size: 65px;
    padding: 20px;
    width: 700px;
    box-shadow: -10px 10px rgb(170, 170, 170);
    transition: transform .5s; /* Animation */
    border: none;
  }
  
  .mintBtns:hover{
    transform: scale(1.2);
  }
  
  .noteMain{
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 40%;
    height: 25vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    overflow: scroll;
    overflow-x: hidden;
    border: white 2px solid;
    box-shadow: -5px 5px rgb(194, 194, 194);
  }
  
  .notOwner{
    color: white;
    text-align: center;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-top: 110px;
  }
  
  .count{
    text-align: left;
    color: white;
    font-size: 20px;
    font-style: italic;
  }
  
  .mintCollect{
    color: black;
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
  }
  
  .mintCollectDis{
    color: rgb(104, 104, 104);
    font-size: 30px;
    border-radius: 10px;
    box-shadow: -5px 5px rgb(100, 100, 100);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
    width: 200px;
    cursor: no-drop;
  }
  
  .mintCollect:hover{
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .nickName{
    border: 4px rgb(78, 78, 78) solid ;
    font-size: 50px;
  }
  
  .login{
    width: 90px;
    height: 90px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .nicknameAndImg{
    display: flex;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
  }
}

